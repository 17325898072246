export const uploadFile = (preSignedUrl: string, file: Blob | File, headers?: { [key: string]: string }) =>
  fetch(preSignedUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': file.type,
      ...(headers ? headers : {})
    },
    body: file
  }).then(response => {
    if (!response.ok) {
      return Promise.reject('Upload file failed')
    }

    return response
  })
